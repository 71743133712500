.paging a, .header nav a, .header input, .header button, .lan_switch a, .cart span, .cart i, .cart b {
    display: none !important;
}

.product_images {
    display: none !important;
}

.product_info_bottom, .addtocart, .gotooutlet {
    display: none !important;
}

.not-on-print {
    display: none !important;
}
.sticky_phone_button {
    display: none !important;
}

.footer_block {
    display: none !important;
}

.only-on-print {
    display: block;
}

.mainimage {
    float: left;
    width: 25%;
}

.mainimage img {
    max-width: 100%;
    vertical-align: middle;
}

.product_info_top {
    float: left;
    width: 74%;
}
table.stock td span.green {
    box-shadow: inset 0 0 0 1000px #9bc53d;
}
table.stock td span {
    box-shadow: inset 0 0 0 1000px #ddd;
}